import React from "react"
import { connect } from "react-redux"
import Link from "redux-first-router-link"
import type { Errors } from "../../state_types"

type Props = {
  errors: Errors
  showFullLoginMessage: boolean
}

const ErrorControls = ({ errors, showFullLoginMessage }: Props) => {
  if (errors.canSignUp) {
    return (
      <div>
        <hr />

        <p>You can add a password to your account.</p>

        <p>You can use this to log in and see your ripples.</p>

        <Link
          to={{ type: "USER_INVITE", payload: { token: errors.inviteToken } }}
        >
          Set your password
        </Link>
      </div>
    )
  }
  if (showFullLoginMessage && errors.canLogIn) {
    return (
      <div>
        <hr />

        <p>
          If you have an account you can{" "}
          <Link to={{ type: "LOGIN" }}>log in</Link> to see more information
          about this ripple.
        </p>
      </div>
    )
  }
  if (errors.canLogIn) {
    return (
      <div>
        <hr />

        <p>
          <Link to={{ type: "LOGIN" }}>Log in</Link> to see your ripples.
        </p>
      </div>
    )
  }

  return <></>
}

export default connect(null)(ErrorControls)
