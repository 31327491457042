import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import get from "lodash.get"
import capitalize from "lodash.capitalize"
import isEmpty from "lodash.isempty"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"
import type {
  BulkActionType,
  DispatchFunction,
  Action,
  Ripple
} from "../state_types"
import { bulkAction } from "../api_actions"
import { TallButton } from "./TallButton"
import Panel, {
  ButtonGroup,
  PanelAction,
  PanelExplanation,
  LeftSide,
  RightSide
} from "./Panel"
import ConfirmationModal, { ConfirmationModalProps } from "./ConfirmationModal"
import { actionIcon } from "../contexts/bulk_actions/icons"
import CalloutContainer from "./CalloutContainer"

const closeButtonStyles = {
  fontSize: "0.875em",
  fontWeight: 700,
  color: colours.blue,
  ":hover": {
    cursor: "pointer"
  }
}

const anyActions = (actions) => !isEmpty(actions)

const PanelClose = styled.span(closeButtonStyles)

const panelCloseIcon = <FontAwesome name="times" style={closeButtonStyles} />

const defaultAllowedActions: Array<BulkActionType> = ["ARCHIVE"]
const noAllowedActions: Array<BulkActionType> = []

type ConfirmationLookup = {
  [name in BulkActionType]?: Array<string>
}

function confirmationQuestion(
  action: BulkActionType,
  count: number
): Array<string> {
  const plural = count > 1 ? "s" : ""
  const questions: ConfirmationLookup = {
    ARCHIVE: [
      `Please confirm you wish to archive ${count} ripple${plural}.`,
      `This will remove access to the Ripple${plural}. This process can only be reversed by an Organisational Admin.`
    ]
  }
  return questions[action]
}

const mapStateToProps = (state) => {
  const selectedRipples = get(state, "bulkActions.data", [])
  return {
    allowedActions: isEmpty(selectedRipples)
      ? noAllowedActions
      : defaultAllowedActions,
    rippleCount: selectedRipples.length,
    syncing: get(state, "bulkActions.syncing") === "sending"
  }
}

const mapDispatchToProps = (dispatch, state) => ({
  performAction: (action: BulkActionType, callback: () => {}) =>
    dispatch(bulkAction(action, callback)),
  exitEditMode: () => dispatch({ type: "BULK_MODE_LEFT" }),
  selectVisibleRipples: () =>
    dispatch({ type: "BULK_RIPPLE_ALL_SELECTED", payload: state.ripples })
})

type EditModePanelProps = {
  rippleCount: number
  allowedActions: Array<BulkActionType>
  performAction:
    | DispatchFunction
    | ((_action: Action | string, _additionalParameters: () => void) => unknown)
  exitEditMode: DispatchFunction
  selectVisibleRipples: DispatchFunction
  ripples: Array<Ripple>
}

type EditModePanelState = {
  modalProps: {
    display: boolean
    question: Array<string>
    title: string
    action?: BulkActionType
  }
}

class EditModePanel extends React.Component<
  EditModePanelProps,
  EditModePanelState
> {
  constructor(props: EditModePanelProps) {
    super(props)
    ;(this as any).confirmAction = this.confirmAction.bind(this)
    ;(this as any).cancelModal = this.cancelModal.bind(this)
    this.state = {
      modalProps: { display: false, question: [], title: "", action: null }
    }
  }

  cancelModal() {
    this.setState({
      modalProps: {
        display: false,
        question: [],
        title: "",
        action: null
      }
    })
  }

  confirmAction(action: BulkActionType) {
    const { rippleCount } = this.props
    const plural = rippleCount > 1 ? "s" : ""

    const title = `${capitalize(action)} Ripple${plural}?`
    const question = confirmationQuestion(action, rippleCount)

    this.setState({
      modalProps: {
        display: true,
        question,
        title,
        action
      }
    })
  }

  render() {
    const { allowedActions, exitEditMode, selectVisibleRipples } = this.props
    const actionButtons = allowedActions.map((action) => {
      const icon = actionIcon(action)
      return (
        <TallButton
          icon={icon}
          label={capitalize(action)}
          onClick={() => this.confirmAction(action)}
        />
      )
    })

    const actions = anyActions(allowedActions) ? (
      <Panel centered topPadding={false}>
        <ButtonGroup group="bulkActions" buttons={actionButtons} />
      </Panel>
    ) : null

    const modalProps: ConfirmationModalProps = {
      ...this.state.modalProps,
      onConfirm: () => {
        this.props.performAction(this.state.modalProps.action, this.cancelModal)
      },
      onCancel: this.cancelModal
    }

    return (
      <CalloutContainer flex="none">
        <Panel topPadding>
          <LeftSide>
            <PanelAction>Edit Ripples</PanelAction>
            <PanelExplanation>
              Select the ripples you wish to edit below
            </PanelExplanation>
            <TallButton
              style={{ marginLeft: "25px" }}
              label="Select all"
              onClick={selectVisibleRipples}
            />
          </LeftSide>
          <RightSide>
            <PanelClose onClick={exitEditMode}>
              {panelCloseIcon} Close
            </PanelClose>
          </RightSide>
        </Panel>
        {actions}
        <ConfirmationModal {...modalProps} />
      </CalloutContainer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModePanel)
