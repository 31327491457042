import camelize from "camelize"
import snakeize from "snakeize"
import immutable from "object-path-immutable"
import { reset } from "redux-form"
import { flashErrorMessage, flashSuccessMessage } from "redux-flash"
import get from "lodash.get"
import omit from "lodash.omit"
import StateDecorator from "./contexts/ripple_form/StateDecorator"
import ImportRipplesStateDecorator from "./contexts/import_ripples_form/StateDecorator"
import api from "./api"
import type {
  AssessmentApiResult,
  GetStateFunc,
  DispatchFunction,
  BulkActionType,
  User
} from "./state_types"
import UserStateDecorator from "./contexts/user/UserStateDecorator"
import {
  removeEmptyMembers,
  prepareDataForSubmit as prepareRippleDataForSubmit
} from "./contexts/ripple_form/wizard"
import { prepareDataForSubmit as prepareImportRipplesDataForSubmit } from "./contexts/import_ripples_form/wizard"
import DetailsApiResult from "./contexts/ripple_details/DetailsApiResult"
import type { ProposedComment } from "./contexts/feed/types"
import { pastTense } from "./contexts/bulk_actions/inflections"

function camelizeAssessment(assessment): AssessmentApiResult {
  if (assessment && assessment.data && assessment.data.previous) {
    return immutable.set(
      camelize(assessment),
      ["data", "previous"],
      assessment.data.previous
    )
  }
  return camelize(assessment)
}

export function generalErrorDispatcher(dispatch: DispatchFunction) {
  return (error) => {
    switch (error.response && error.response.status) {
      case 403:
        dispatch(
          flashErrorMessage(
            "Sorry, I can't show you this page. Please sign in.",
            { timeout: 5000 }
          )
        )
        dispatch({ type: "ACCESS_DENIED" })
        break
      case 404:
        dispatch({ type: "@@redux-first-router/NOT_FOUND" })
        break
      case 409:
        dispatch({ type: "CLIENT_OUTDATED" })
        break
      default:
        dispatch(
          flashErrorMessage("Oops, something unexpected happened.", {
            timeout: 5000
          })
        )
    }
  }
}

export type JsonResponse = {
  assessment?
  current_user?
  errors?
  error?
  success?
  org_roles?
  data?: {
    token?
    invite_token?
    success?
    user?
  }
  mfa_enabled?
  mfa_required?
  mfa_setup_url?
}

export function getOpenAssessment(token: string) {
  return (dispatch: DispatchFunction) => {
    api.assessments
      .new(token)
      .then((json: JsonResponse) => {
        const { data } = json

        if (data && data.invite_token) {
          dispatch({
            type: "USER_INVITE",
            payload: { token: data.invite_token }
          })
        } else {
          const payload = {
            currentUser: camelize(json.current_user),
            assessment: camelizeAssessment(json.assessment)
          }
          dispatch({ type: "RECEIVED_OPEN_ASSESSMENT", payload })
        }
      })
      .catch(generalErrorDispatcher(dispatch))
  }
}

export function postAssessment(assessment: AssessmentApiResult) {
  return (dispatch: DispatchFunction) => {
    if (assessment.data) {
      dispatch({ type: "SENDING_ASSESSMENT_STARTED" })
      const assessmentData = { ...assessment.data.input }
      api.assessments
        .create(assessment.links.submit, assessmentData)
        .then((json: JsonResponse) => {
          const { data } = json
          const errors = json.assessment && json.assessment.errors
          if (errors) {
            dispatch(flashErrorMessage(errors.title, { timeout: 5000 }))
            dispatch({ type: "HOME" })
          } else if (data.token) {
            dispatch({
              type: "RECEIVED_CURRENT_USER",
              payload: camelize(json)
            })
            dispatch({ type: "HOME" })
          } else if (data.invite_token) {
            dispatch({
              type: "USER_INVITE",
              payload: { token: data.invite_token },
              meta: { query: { assessed: "true" } }
            })
          } else if (data.success) {
            dispatch({ type: "HOME" })
            dispatch(
              flashSuccessMessage("Thank you for submitting the assessment.")
            )
          } else {
            dispatch({ type: "HOME" })
          }
        })
    }
  }
}

type SubmitCallbacks = {
  onSubmitting: () => any
  onSubmitted: () => any
  onError: (errorJson: {}) => any
}

export function submitRippleComment(
  rippleUuid: string,
  comment: ProposedComment,
  callbacks: SubmitCallbacks
) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_RIPPLE_COMMENT" })
    callbacks.onSubmitting()

    let data = { ripple_uuid: rippleUuid, text: comment.text }

    if (comment.attachment) {
      const { file, role } = comment.attachment
      const attachmentData = {
        attachment_file: file,
        attachment_role: role
      }
      data = { ...data, ...attachmentData }
    }

    api.feed
      .addComment(data, getState)
      .then((json) => {
        dispatch({ type: "SUBMITTED_RIPPLE_COMMENT", payload: camelize(json) })
        callbacks.onSubmitted()
      })
      .catch((error) => {
        error.response
          .json()
          .then((errorJson) => {
            callbacks.onError(errorJson.errors)
            dispatch({
              type: "SUBMITTING_RIPPLE_COMMENT_FAILED",
              payload: { errors: errorJson.errors }
            })
          })
          .catch(generalErrorDispatcher(dispatch))
      })

    return null
  }
}

export function submitDevLogin(emailOrUsername: string) {
  return (dispatch: DispatchFunction) => {
    dispatch({ type: "SUBMITTED_LOGIN" })

    const data = snakeize({
      emailOrUsername
    })

    api.dev
      .login(data)
      .then((json: JsonResponse) => {
        if (json.data?.token && json.data?.user) {
          // clears afterLogin*
          dispatch({ type: "RECEIVED_CURRENT_USER", payload: camelize(json) })
          dispatch({ type: "HOME" })
        } else if (json.errors) {
          console.error(json.errors) // eslint-disable-line no-console
        } else {
          dispatch({
            type: "LOGIN_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        console.error(error) // eslint-disable-line no-console
        generalErrorDispatcher(dispatch)(error)
      })
  }
}

export function submitLogin(
  emailOrUsername: string,
  password: string,
  otpCode: string,
  otpCodeForUnconfirmedSecret: string
) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTED_LOGIN" })
    const userState = new UserStateDecorator(getState())

    const data = snakeize({
      emailOrUsername,
      password,
      otpCode,
      otpCodeForUnconfirmedSecret
    })

    api.user
      .login(data)
      .then((json: JsonResponse) => {
        if (json.data?.token && json.data?.user) {
          const afterLoginAction = userState.afterLoginAction()
          const afterLoginRedirect = userState.afterLoginRedirect()

          // clears afterLogin*
          dispatch({ type: "RECEIVED_CURRENT_USER", payload: camelize(json) })
          if (afterLoginRedirect) {
            window.location.assign(afterLoginRedirect)
          } else if (afterLoginAction) {
            dispatch(afterLoginAction)
          } else {
            dispatch({ type: "HOME" })
          }
        } else if (json.mfa_enabled) {
          dispatch({ type: "LOGIN_ENTER_OTP", payload: camelize(json) })
        } else if (json.mfa_required) {
          dispatch({ type: "LOGIN_SETUP_OTP", payload: camelize(json) })
        } else if (json.errors) {
          dispatch({ type: "LOGIN_FAILED", payload: camelize(json) })
        } else {
          dispatch({
            type: "LOGIN_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_enabled) {
          dispatch({ type: "LOGIN_ENTER_OTP", payload: camelize(error.json) })
        } else if (error.json?.mfa_required) {
          dispatch({ type: "LOGIN_SETUP_OTP", payload: camelize(error.json) })
        } else if (error.json?.errors) {
          dispatch({ type: "LOGIN_FAILED", payload: camelize(error.json) })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }
}

export function submitUpdateOtp(
  password: string,
  otpCode: string,
  otpCodeForUnconfirmedSecret: string
) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTED_UPDATE_OTP" })
    const data = snakeize({ password, otpCode, otpCodeForUnconfirmedSecret })

    api.user
      .updateOTP(data, getState)
      .then((json: JsonResponse) => {
        if (json.success) {
          dispatch({ type: "MANAGE_MFA", payload: camelize(json) })
        } else if (json.mfa_setup_url) {
          dispatch({ type: "UPDATE_OTP_SETUP_OTP", payload: camelize(json) })
        } else if (json.mfa_enabled) {
          dispatch({ type: "UPDATE_OTP_ENTER_OTP", payload: camelize(json) })
        } else if (json.errors) {
          dispatch({ type: "UPDATE_OTP_FAILED", payload: camelize(json) })
        } else {
          dispatch({
            type: "UPDATE_OTP_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_setup_url) {
          dispatch({
            type: "UPDATE_OTP_SETUP_OTP",
            payload: camelize(error.json)
          })
        } else if (error.json?.mfa_enabled) {
          dispatch({
            type: "UPDATE_OTP_ENTER_OTP",
            payload: camelize(error.json)
          })
        } else if (error.json?.errors) {
          dispatch({ type: "UPDATE_OTP_FAILED", payload: camelize(error.json) })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }
}

export function logout() {
  // log out the front end even if something goes wrong when logging out the backend
  return (dispatch: DispatchFunction) => {
    return api.user
      .logout()
      .then(() => {
        dispatch({ type: "LOGOUT" })
      })
      .catch((error) => {
        dispatch({ type: "LOGOUT" })
        generalErrorDispatcher(dispatch)(error)
      })
  }
}

export function submitDisableOtp(password: string, otpCode: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTED_DISABLE_OTP" })
    const data = snakeize({ password, otpCode })

    api.user
      .disableOTP(data, getState)
      .then((json: JsonResponse) => {
        if (json.success) {
          dispatch({ type: "MANAGE_MFA", payload: camelize(json) })
        } else if (json.mfa_enabled) {
          dispatch({ type: "DISABLE_OTP_ENTER_OTP", payload: camelize(json) })
        } else if (json.errors) {
          dispatch({ type: "DISABLE_OTP_FAILED", payload: camelize(json) })
        } else {
          dispatch({
            type: "DISABLE_OTP_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_required) {
          dispatch({
            type: "MANAGE_MFA",
            payload: camelize(error.json)
          })
        } else if (error.json?.mfa_enabled) {
          dispatch({
            type: "DISABLE_OTP_ENTER_OTP",
            payload: camelize(error.json)
          })
        } else if (error.json?.errors) {
          dispatch({
            type: "DISABLE_OTP_FAILED",
            payload: camelize(error.json)
          })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }
}

export function submitRemoveBackupCodes(password: string, otpCode: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTED_REMOVE_BACKUP_CODES" })
    const data = snakeize({ password, otpCode })

    api.user
      .removeBackupCodes(data, getState)
      .then((json: JsonResponse) => {
        if (json.success) {
          dispatch({ type: "MANAGE_MFA", payload: camelize(json) })
        } else if (json.mfa_enabled) {
          dispatch({
            type: "REMOVE_BACKUP_CODES_ENTER_OTP",
            payload: camelize(json)
          })
        } else if (json.errors) {
          dispatch({
            type: "REMOVE_BACKUP_CODES_FAILED",
            payload: camelize(json)
          })
        } else {
          dispatch({
            type: "REMOVE_BACKUP_CODES_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_enabled) {
          dispatch({
            type: "REMOVE_BACKUP_CODES_ENTER_OTP",
            payload: camelize(error.json)
          })
        } else if (error.json?.errors) {
          dispatch({
            type: "REMOVE_BACKUP_CODES_FAILED",
            payload: camelize(error.json)
          })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }
}

export function submitCreateBackupCodes(password: string, otpCode: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTED_CREATE_BACKUP_CODES" })
    const data = snakeize({ password, otpCode })

    api.user
      .createBackupCodes(data, getState)
      .then((json: JsonResponse) => {
        if (json.success) {
          dispatch({ type: "DISPLAY_BACKUP_CODES", payload: camelize(json) })
        } else if (json.mfa_enabled) {
          dispatch({
            type: "CREATE_BACKUP_CODES_ENTER_OTP",
            payload: camelize(json)
          })
        } else if (json.errors) {
          dispatch({
            type: "CREATE_BACKUP_CODES_FAILED",
            payload: camelize(json)
          })
        } else {
          dispatch({
            type: "CREATE_BACKUP_CODES_FAILED",
            payload: { errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_enabled) {
          dispatch({
            type: "CREATE_BACKUP_CODES_ENTER_OTP",
            payload: camelize(error.json)
          })
        } else if (error.json?.errors) {
          dispatch({
            type: "CREATE_BACKUP_CODES_FAILED",
            payload: camelize(error.json)
          })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }
}

export function submitRippleForm() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_RIPPLE_FORM" })
    let formValues = getState().form.ripple.values

    formValues = {
      ...formValues,
      members: removeEmptyMembers(formValues.members)
    }

    const data = snakeize(prepareRippleDataForSubmit(formValues))

    api.ripples.create(data, getState).then((_response) => {
      dispatch({ type: "SUBMITTED_RIPPLE_FORM" })
      dispatch(reset("ripple"))
      dispatch({ type: "RIPPLES" })
    })

    return null
  }
}

export function submitImportRipplesForm() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_IMPORT_RIPPLES_FORM" })
    const state = getState()
    const { values } = state.form.importRipples

    const data = snakeize(prepareImportRipplesDataForSubmit(values))

    api.ripples.bulk.import(data, getState).then((_response) => {
      dispatch({ type: "SUBMITTED_IMPORT_RIPPLES_FORM" })
      dispatch(
        flashSuccessMessage(`The ripples are being imported in the background.`)
      )
      dispatch(reset("importRipples"))
      dispatch({ type: "RIPPLES" })
    })

    return null
  }
}

export function submitOrgArchiveRipplesForm() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_ORG_ARCHIVE_RIPPLES_FORM" })
    const state = getState()
    const { slug } = state.location.payload
    const { values } = state.form.orgArchiveRipples
    const orgName = state.org.name

    const data = snakeize(values)

    api.org.archiveRipples(slug, data, getState).then((json) => {
      dispatch({
        type: "SUBMITTED_ORG_ARCHIVE_RIPPLES_FORM",
        payload: camelize(json)
      })
      dispatch({ type: "ORG", payload: { slug } })
      dispatch(flashSuccessMessage(`You archived Ripples for ${orgName}.`))
    })

    return null
  }
}

export function submitEditUserForm(userEmail: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_EDIT_USER_FORM" })
    const formValues = getState().form.userEdit.values

    const data = snakeize(formValues)

    api.user.update(data, getState, userEmail).then((json) => {
      dispatch({ type: "SUBMITTED_EDIT_USER_FORM", payload: camelize(json) })
      dispatch({ type: "HOME" })
    })

    return null
  }
}

export function submitEditRippleForm() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_EDIT_RIPPLE_FORM" })
    const formValues = getState().form.userEditRipple.values
    const { rippleUuid } = getState().location.payload

    const data = snakeize(formValues)

    api.ripples.update(data, getState, rippleUuid).then(() => {
      dispatch({ type: "SUBMITTED_EDIT_RIPPLE_FORM" })
      dispatch({ type: "RIPPLE", payload: { rippleUuid } })
      dispatch(flashSuccessMessage("Ripple updated"))
    })

    return null
  }
}

export function submitEditPasswordForm(userEmail: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_USER_PASSWORD_FORM" })

    const formValues = getState().form.userPassword.values
    const data = snakeize(omit(formValues, ["passwordConfirmation"]))

    api.user.updatePassword(data, getState, userEmail).then((_response) => {
      dispatch({ type: "SUBMITTED_USER_PASSWORD_FORM" })
      dispatch({ type: "HOME" })
    })

    return null
  }
}

export function requestNewPasswordLink(email: string) {
  return (dispatch: DispatchFunction) => {
    api.user.newPassword(email).then((_response) => {
      dispatch({ type: "FORGOT_PASSWORD_SENT" })
    })

    return null
  }
}

export function submitResetPassword(
  password: string,
  token: string,
  onFailure: (error: string) => void
) {
  return (dispatch: DispatchFunction) => {
    const data = {
      password,
      token
    }

    api.user.resetPassword(data).then((json: JsonResponse) => {
      if (json.success) {
        dispatch(
          flashSuccessMessage(
            "Your password has been updated, you can now use it to log in."
          )
        )
        dispatch({ type: "LOGIN" })
      } else {
        onFailure(json.error)
      }
    })
  }
}

export function submitAddUserForm() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "SUBMITTING_ADD_USER_FORM" })
    const formValues = getState().form.addUser.values

    const data = snakeize(formValues)
    const { slug } = getState().location.payload

    api.orgUsers.create(slug, data, getState).then((json) => {
      dispatch({ type: "SUBMITTED_ADD_USER_FORM", payload: camelize(json) })
      dispatch(reset("addUser"))
    })

    return null
  }
}

export function updateOrgUserRole(
  email: string,
  roleName: string,
  roleValue: boolean
) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const currentUserEmail = get(getState(), "me.data.user.email")
    if (currentUserEmail === email && roleName === "admin") {
      alert("You cannot change your own admin role")
      return
    }

    const { slug } = getState().location.payload

    dispatch({
      type: "UPDATING_ORG_ROLE",
      payload: { email, roleName, roleValue, orgSlug: slug }
    })

    api.orgUsers
      .update_role(
        slug,
        email,
        { role: roleName, role_value: roleValue },
        getState
      )
      .then((json: JsonResponse) => {
        const roles = json.org_roles
        dispatch({
          type: "UPDATED_ORG_ROLE",
          payload: { email, roles, orgSlug: slug }
        })
      })
  }
}

export function deleteOrgUser(email: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "DELETING_ORG_USER", payload: { email } })
    const { slug } = getState().location.payload

    api.orgUsers.remove_org_roles(slug, email, getState).then((_json) => {
      dispatch({ type: "REMOVED_ORG_USER", payload: { email } })
    })
  }
}

export function resendOrgUserInvite(email: string) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "RESENDING_ORG_USER", payload: { email } })
    const { slug } = getState().location.payload

    api.orgUsers.resend_invite(slug, email, getState).then((_json) => {
      dispatch({ type: "SENT_ORG_USER", payload: { email } })
    })
  }
}

export function updateUserTags(email: string, tags: Array<string>) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const { slug } = getState().location.payload

    api.orgUsers.update_tags(slug, email, { tags }, getState).then(() => {
      dispatch({ type: "UPDATED_USER_TAGS", payload: { email, tags } })
    })
  }
}

export function updateUser(user: User, values: {}) {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const { slug } = getState().location.payload

    api.orgUsers
      .updateUser(slug, user.id, values, getState)
      .then((json) => {
        dispatch({ type: "UPDATED_USER", payload: camelize(json) })
        dispatch(flashSuccessMessage("User updated"))
      })
      .catch(generalErrorDispatcher(dispatch))
  }
}

export const fetchNewRippleForm = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const formState = new StateDecorator(getState())

  const { clientId } = getState().location.payload

  const query = clientId
    ? { clientId }
    : { organisation: formState.selectedOrganisationForRipple() }

  api.ripples.new(snakeize(query), getState).then((json) => {
    dispatch({ type: "NEW_RIPPLE_OPTIONS", payload: camelize(json) })
  })
}

export const fetchImportRipplesForm = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const formState = new ImportRipplesStateDecorator(getState())

  const query = { organisation: formState.selectedOrganisationForRipple() }

  api.ripples.new(snakeize(query), getState).then((json) => {
    dispatch({ type: "IMPORT_RIPPLES_OPTIONS", payload: camelize(json) })
  })
}

export const fetchMyRippleList = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const userState = new UserStateDecorator(getState())
  const defaultRippleUuid = userState.defaultRippleUuid()

  if (defaultRippleUuid) {
    dispatch({ type: "RIPPLE", payload: { rippleUuid: defaultRippleUuid } })
    return
  }

  const filters = getState().myRipples.filters
  const query = snakeize(filters)

  api.ripples
    .index(query, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_MY_RIPPLE_LIST", payload: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchArchivedRippleList = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const filters = getState().archivedRipples.filters
  const query = snakeize(filters)

  api.ripples
    .archived(query, getState)
    .then((json) => {
      dispatch({
        type: "RECEIVED_ARCHIVED_RIPPLE_LIST",
        payload: camelize(json)
      })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchRipple = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const { rippleUuid } = getState().location.payload

  const existing = new DetailsApiResult(getState().rippleDetails)
  if (!existing.validForRipple(rippleUuid)) {
    api.ripples
      .show(rippleUuid, getState)
      .then((json) => {
        dispatch({ type: "RECEIVED_RIPPLE", payload: camelize(json) })
      })
      .catch(generalErrorDispatcher(dispatch))
  }
}

export const fetchRipplesForQrManagement = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const state = getState()
  const filters = get(state, "qrCodes.filters") || {}

  api.qrCodes
    .ripples(filters, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_QR_RIPPLES", payload: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const setQrStatus = (rippleRoleId: number, activeStatus: boolean) => {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({
      type: "UPDATING_QR_STATUS",
      payload: { rippleRoleId, activeStatus }
    })

    api.qrCodes.setStatus(rippleRoleId, activeStatus, getState).then((json) => {
      dispatch({
        type: "UPDATED_QR_STATUS",
        payload: camelize(json)
      })
    })
  }
}

export const fetchReportIndex = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const { query } = getState().location

  api.report
    .index(query, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_REPORT_INDEX", data: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchReport = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const location = getState().location
  const { reportId } = location.payload
  const { query } = location

  api.report
    .show(reportId, query, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_REPORT", reportId, data: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchSubReport = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const location = getState().location
  const { reportId, subReportId } = location.payload
  let { query } = location

  query = snakeize({ subreport: "section", subReportId, ...query })

  api.report
    .show(reportId, query, getState)
    .then((json) => {
      dispatch({
        type: "RECEIVED_SUB_REPORT",
        reportId,
        subReportId,
        data: camelize(json)
      })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchOrg = (options) => {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const { slug } = getState().location.payload

    api.org
      .show(slug, getState)
      .then((json) => {
        dispatch({ type: "RECEIVED_ORG", payload: camelize(json) })
        if (options.then) {
          options.then(dispatch, getState)
        }
      })
      .catch(generalErrorDispatcher(dispatch))
  }
}

export const fetchOrgFrameworks = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const { slug } = getState().location.payload
  api.orgFrameworks
    .index(slug, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_ORG_FRAMEWORKS", payload: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchOrgUsers = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const { slug } = getState().location.payload

  const filters = getState().org.filters
  const query = snakeize(filters)

  api.orgUsers
    .index(slug, query, getState)
    .then((json) => {
      dispatch({ type: "RECEIVED_ORG_USERS", payload: camelize(json) })
    })
    .catch(generalErrorDispatcher(dispatch))
}

export const fetchUserInvite = (
  dispatch: DispatchFunction,
  getState: GetStateFunc
) => {
  const { token } = getState().location.payload
  api.userInvite.show(token).then((json) => {
    dispatch({ type: "RECEIVED_USER_INVITE", payload: camelize(json) })
  })
}

export const acceptUserInvite =
  (token: string, values: {}) => (dispatch: DispatchFunction) => {
    dispatch({ type: "ACCEPTING_USER_INVITE" })
    api.userInvite
      .accept(token, snakeize(values))
      .then((json) => {
        if (json.data?.token && json.data?.user) {
          dispatch({ type: "RECEIVED_CURRENT_USER", payload: camelize(json) })
          dispatch({ type: "HOME" })
        } else if (json.mfa_required) {
          dispatch({
            type: "ACCEPT_USER_INVITE_SETUP_OTP",
            payload: { token, ...camelize(json) }
          })
        } else {
          dispatch({
            type: "USER_INVITE",
            payload: { token, errors: { title: "Incomplete response" } }
          })
        }
      })
      .catch((error) => {
        if (error.json?.mfa_required) {
          dispatch({
            type: "ACCEPT_USER_INVITE_SETUP_OTP",
            payload: { token, ...camelize(error.json) }
          })
        } else {
          generalErrorDispatcher(dispatch)(error)
        }
      })
  }

export const closeRippleAssessment =
  (rippleUuid: string) =>
  (dispatch: DispatchFunction, getState: GetStateFunc) => {
    dispatch({ type: "CLOSING_RIPPLE_ASSESSMENT", payload: { rippleUuid } })
    api.ripples
      .closeAssessment(rippleUuid, getState)
      .then((json) => {
        dispatch({ type: "CLOSED_RIPPLE_ASSESSMENT", payload: { rippleUuid } })
        dispatch({ type: "RECEIVED_RIPPLE", payload: camelize(json) })
      })
      .catch(generalErrorDispatcher(dispatch))
  }

export const sendAssessmentReminder =
  (rippleUuid: string, userId: number, onComplete: () => void) =>
  (dispatch: DispatchFunction, getState: GetStateFunc) => {
    api.ripples
      .sendReminder(rippleUuid, userId, getState)
      .then(() => {
        onComplete()
        dispatch({
          type: "SENT_ASSESSMENT_REMINDER",
          payload: { rippleUuid, userId }
        })
      })
      .catch(generalErrorDispatcher(dispatch))
  }

export const archiveRipple =
  (rippleUuid: string) =>
  (dispatch: DispatchFunction, getState: GetStateFunc) => {
    api.ripples
      .archive(rippleUuid, getState)
      .then((json) => {
        dispatch({ type: "RIPPLE_ARCHIVED", payload: camelize(json) })
        dispatch({ type: "RIPPLES", payload: {} })
      })
      .catch(generalErrorDispatcher(dispatch))
  }

export const bulkAction =
  (action: BulkActionType, onComplete: () => {}) =>
  (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const state = getState()
    const rippleUuids: Array<string> = state.bulkActions.data

    dispatch({ type: "PERFORMING_BULK_ACTION", payload: { type: action } })
    return api.ripples.bulk
      .archive({ ripple_ids: rippleUuids }, getState)
      .then((json: JsonResponse) => {
        if (json.errors) {
          dispatch(flashErrorMessage(json.errors.title, { timeout: 5000 }))
        } else {
          dispatch(
            flashSuccessMessage(`Ripple(s) ${pastTense(action).toLowerCase()}.`)
          )
          dispatch({ type: "BULK_ACTION", payload: camelize(json) })
        }
      })
      .then(() => fetchMyRippleList(dispatch, getState))
      .then(() => onComplete())
      .catch(generalErrorDispatcher(dispatch))
  }

export const restoreRipple =
  (rippleUuid: string) =>
  (dispatch: DispatchFunction, getState: GetStateFunc) => {
    api.ripples
      .restore(rippleUuid, getState)
      .then((json) => {
        dispatch({ type: "RIPPLE_RESTORED", payload: camelize(json) })
        dispatch({ type: "RECEIVED_RIPPLE", payload: camelize(json) })
        dispatch({ type: "EDIT_RIPPLE_MEMBERS", payload: { rippleUuid } })
      })
      .catch(generalErrorDispatcher(dispatch))
  }
