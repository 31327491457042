import React from "react"
import styled from "@emotion/styled"
import NoAssessmentInvitation from "./errors/NoAssessmentInvitation"
import AssessmentAlreadyCompleted from "./errors/AssessmentAlreadyCompleted"
import AssessmentStartingSoon from "./errors/AssessmentStartingSoon"

import type { Errors } from "../state_types"

const Overlay = styled.div({
  backgroundColor: "#EEE",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
})

const Status = styled.h1({})

type ErrorComponent = ({ errors }: { errors: Errors }) => JSX.Element

const specificErrors = {
  NoAssessmentInvitation,
  AssessmentAlreadyCompleted,
  AssessmentStartingSoon
}

const GenericFatalError = ({ errors }: { errors: Errors }) => {
  return (
    <div>
      {errors.status && <Status>{errors.status}</Status>}
      {errors.title && <p>{errors.title}</p>}
    </div>
  )
}

const BestErrorComponent = (errorId: string): ErrorComponent => {
  return specificErrors[errorId] || GenericFatalError
}

const FatalError = ({ errors }: { errors: Errors }) => {
  const InternalError: ErrorComponent = BestErrorComponent(errors.id)
  return (
    <Overlay>
      <InternalError errors={errors} />
    </Overlay>
  )
}

export default FatalError
