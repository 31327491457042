import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import type { Errors } from "../../state_types"
import ErrorControls from "./ErrorControls"
import NextAssessmentAtPresenter from "./NextAssessmentAtPresenter"

const Centered = styled.div({
  textAlign: "center"
})

type Props = {
  errors: Errors
}

const AssessmentStartingSoon = ({ errors }: Props) => {
  return (
    <Centered>
      <h2>{errors.title}</h2>
      {errors.nextAssessmentAt && (
        <p>
          It will be open again at{" "}
          <NextAssessmentAtPresenter
            nextAssessmentAt={errors.nextAssessmentAt}
          />
        </p>
      )}
      <ErrorControls errors={errors} showFullLoginMessage={false} />
    </Centered>
  )
}

export default connect(null)(AssessmentStartingSoon)
