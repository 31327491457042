const settings = {
  users: {
    canSeeOrgAdminButton: [
      "gn@ripple.com.au",
      "ed@ripple.com.au",
      "shs@ripple.com.au",
      "org_admin@user.com"
    ]
  }
}

export default settings
