import React from "react"
import { connect } from "react-redux"

type Props = {
  nextAssessmentAt: string
}

const NextAssessmentAtPresenter = ({ nextAssessmentAt }: Props) => {
  const nextAssessmentAtArray = nextAssessmentAt.split(" on ")

  return (
    <>
      <strong>{nextAssessmentAtArray[0]}</strong> on{" "}
      <strong>{nextAssessmentAtArray[1]}</strong>
    </>
  )
}

export default connect(null)(NextAssessmentAtPresenter)
