import immutable from "object-path-immutable"
import get from "lodash.get"
import findIndex from "lodash.findindex"
import { applyReducerHash } from "../../redux_helpers"
import type {
  BulkActionsApiResult,
  Action,
  BulkAction
} from "../../state_types"

const isUUID: (_uuid: string) => (_other: string) => boolean =
  (uuid: string) => (other: string) =>
    uuid === other

const reducers = {
  BULK_MODE_ENTERED: (state?: BulkActionsApiResult) => {
    if (state) {
      return {
        ...state,
        active: true
      }
    }

    return { active: true, data: [] }
  },
  BULK_MODE_LEFT: (_state?: BulkActionsApiResult) => {
    return { active: false, data: [] }
  },
  BULK_RIPPLE_ALL_SELECTED: (
    state: BulkActionsApiResult | null | undefined,
    action: Action
  ) => {
    return { active: true, data: action.payload.map((ripple) => ripple.uuid) }
  },
  BULK_RIPPLE_SELECTED: (
    state: BulkActionsApiResult | null | undefined,
    action: Action
  ) => {
    const existing = findIndex(get(state, "data"), isUUID(action.payload))
    return existing === -1
      ? immutable.push(state, "data", action.payload)
      : state
  },
  BULK_RIPPLE_DESELECTED: (
    state: BulkActionsApiResult | null | undefined,
    action: Action
  ) => {
    const existing = findIndex(get(state, "data"), isUUID(action.payload))
    return existing !== -1 ? immutable.del(state, `data.${existing}`) : state
  },

  PERFORMING_BULK_ACTION: (
    state: BulkActionsApiResult | null | undefined,
    _action: BulkAction
  ) => {
    return { ...state, syncing: "sending" }
  },
  BULK_ACTION: (
    state: BulkActionsApiResult | null | undefined,
    action: BulkAction
  ) => {
    if (action.type === "ARCHIVE") {
      return { ...state, data: [], syncing: "receiving" }
    }

    return { ...state, syncing: "receiving" }
  }
}

const INITIAL_STATE: BulkActionsApiResult = {
  active: false,
  data: []
}

export default function bulkActionsReducer(
  state: BulkActionsApiResult = INITIAL_STATE,
  action: Action
): BulkActionsApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
