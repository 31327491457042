import React from "react"
import styled from "@emotion/styled"
import Markdown from "react-markdown"

import isNil from "lodash.isnil"
import RippleGA from "../../../ripple_ga"
import SimpleRadioAssessmentSummary from "./SimpleRadioAssessmentSummary"
import SimpleRadioIntro from "./SimpleRadioIntro"
import SimpleRadioQuestion from "./SimpleRadioQuestion"
import SimpleRadioInstruction from "./SimpleRadioInstructions"
import SimpleRadioSectionSummary from "./SimpleRadioSectionSummary"
import type {
  Assessment,
  Question,
  AssessmentInput,
  QuestionInput,
  SectionSummary,
  AssessmentIntro,
  Attribute,
  AssessmentInstruction
} from "../../../contexts/assessment/types"
import BlueTheme from "../../../themes/Blue"
import colours from "../../../themes/colours"
import SimpleRadioCard from "./SimpleRadioCard"
import SimpleRadioProgressBar from "./SimpleRadioProgressBar"
import { Steps } from "../../../contexts/shared/step_types"
import { mediaQueries } from "../../Layout"
import SimpleRadioButton from "./SimpleRadioButton"

const Background = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column"
})

const SimpleRadioWrapper = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundColor: colours.white,
  color: "black"
})

const SimpleRadioFooter = styled.div<{ secondary?: boolean }>(
  ({ secondary }) => {
    return {
      backgroundColor: secondary ? colours.lightGrey : colours.lightGreen,
      color: "black",
      padding: "11px 18px",
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      fontSize: "11px",
      flexWrap: "wrap",
      [mediaQueries.desktop]: {
        padding: "2rem 1.5rem 1rem 1.5rem",
        borderRadius: "0 0 10px 10px"
      }
    }
  }
)

const FooterText = styled.span({
  order: 2,
  width: "100%",
  margin: "10px auto 0 auto",
  textAlign: "center",
  [mediaQueries.desktop]: {
    order: 0,
    width: "auto"
  }
})

const emptyInput = {
  attributes: {}
}

const questionInput = (
  question: Question,
  assessmentInput: AssessmentInput
): QuestionInput | null | undefined => {
  return assessmentInput.attributes[question.id]
}

type SimpleRadioHOCProps = {
  assessment: Assessment
  nextPage: () => unknown
  previousPage: () => unknown
  updateRating: (questionId: string, rating?: number) => unknown
  submitAssessment: () => unknown
  step: Attribute
}

const SimpleRadioHOC: React.FC<SimpleRadioHOCProps> = ({
  assessment,
  nextPage,
  previousPage,
  updateRating,
  submitAssessment,
  step
}: SimpleRadioHOCProps) => {
  const { framework, canSkipQuestions } = assessment

  const input: QuestionInput | null | undefined =
    step.type === "question"
      ? questionInput(step, assessment.input || emptyInput)
      : null

  const isIntro = step.type === "assessment_intro"
  const isQuestion = step.type === "question"
  const isAssessmentSummary = step.type === "assessment_summary"
  const isLastSectionSummary =
    step.type === "section_summary" && !step.nextSectionTitle

  const showPrev = !isAssessmentSummary && !isIntro
  const showNext = !isAssessmentSummary && !isIntro && !isLastSectionSummary

  const assessmentSummaryFooter = framework.frameworkFooter ?? framework.footer

  const hasRating = !isNil(input?.rating)
  const disabled = isQuestion && !hasRating && !canSkipQuestions

  const nextPageAssessmentStart = (): unknown => {
    if (isIntro) {
      RippleGA.assessmentStart(assessment)
    }
    return nextPage()
  }

  const nextPageAssessmentFinish = (): unknown => {
    if (isLastSectionSummary) {
      RippleGA.assessmentFinish(assessment)
    }
    return nextPage()
  }

  const slide = () => {
    switch (step.type) {
      case "question": {
        return (
          <SimpleRadioQuestion
            current={assessment.currentStage === step.id}
            step={step}
            input={input}
            updateRating={(rating) => updateRating(step.id, rating)}
            framework={framework}
          />
        )
      }

      case "assessment_intro": {
        const introStep = step as AssessmentIntro
        return (
          <SimpleRadioIntro
            step={introStep}
            nextPage={nextPageAssessmentStart}
          />
        )
      }

      case "assessment_instruction": {
        const introStep = step as AssessmentInstruction
        return <SimpleRadioInstruction step={introStep} />
      }

      case "section_summary": {
        const summary = step as SectionSummary
        return (
          <SimpleRadioSectionSummary
            step={summary}
            nextPage={nextPageAssessmentFinish}
          />
        )
      }

      case "assessment_summary":
        return (
          <SimpleRadioAssessmentSummary submitAssessment={submitAssessment} />
        )

      default:
        return (
          <div>
            unknown section type
            {step.type}
          </div>
        )
    }
  }

  return (
    <BlueTheme key={step.id}>
      <Background>
        <SimpleRadioCard>
          <SimpleRadioProgressBar
            steps={framework.steps as Steps}
            currentStepID={step.id}
          />
          <SimpleRadioWrapper>{slide()}</SimpleRadioWrapper>
          <SimpleRadioFooter secondary={isAssessmentSummary}>
            {showPrev && (
              <SimpleRadioButton
                onClick={previousPage}
                icon="chevLeft"
                prefixIcon
                secondary
                text="Back"
              />
            )}
            <FooterText>
              {isAssessmentSummary ? (
                <Markdown>{assessmentSummaryFooter}</Markdown>
              ) : (
                framework.footer
              )}
            </FooterText>
            {showNext && (
              <SimpleRadioButton
                onClick={!disabled && nextPage}
                icon="chevRight"
                text="Next"
                disabled={disabled}
              />
            )}
          </SimpleRadioFooter>
        </SimpleRadioCard>
      </Background>
    </BlueTheme>
  )
}

export default SimpleRadioHOC
